$(function() {
    let phone = $('.phone-number-masked');
    let im = new Inputmask('38 (999) 999 - 99 - 99');
    im.mask(phone);

    phone.change((e) => {
        let phone = e.target.value.match(/\d/g);
        if (phone) {
            phone = phone.join("");
            //Livewire.emit('SetFieldFromInputMask', {phone: phone});
        }
    });

    window.addEventListener('alpineRefresh', async (event) => {
        let phone = $('.phone-number-masked');
        let im = new Inputmask('38 (999) 999 - 99 - 99');
        im.mask(phone);
    });
})
