import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static targets = [ "email", "error", "btnSend", "key" , "yes", "btn"]

    connect() {
        console.log('home key Activate controller')
    }

    check(e) {
        e.preventDefault();
        console.log('Send KEY')
        if (this.yesTarget.checked) {
            this.btnTarget.removeAttribute('disabled', "");
        } else {
            this.btnTarget.setAttribute('disabled', "");
        }
    }

    send(e) {
        e.preventDefault();
        console.log('Send KEY')
        let form = $(this.element);
        const contr = this
        $.ajax({
            url: form.attr('action'),
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (response) {
                //console.log(response);
                if (response.success) {
                    // console.log(response)
                    window.location.href = response.redirect
                } else if (response.errors) {
                    console.log(response.errors)
                } else {
                    console.error('Uncaught Error')
                }
            },
            error: function (response) {
                let result = $.parseJSON(response.responseText);
                console.log(result)
                $.each(result.errors, function(key, value) {
                    $(contr.targets.find(key)).css('border', '1px solid red');
                    $.each(value, function(i, label) {
                        $(contr.errorTarget).text(label).removeClass('d-none')
                    });
                });
                if (result.message) {
                    $(contr.errorTarget).text(result.message).removeClass('d-none')
                }
            }
        });
    }
}
